<template>
    <div id="app" class="">
        <router-view/>
        <formular/>
    </div>
</template>

<script>

import Formular from "./components/Formular";

export default {
    name: 'App',
    components: {
        Formular,
    },
    methods: {
        changeLanguage(language) {
            this.$i18n.locale = language;
        },
        openFormular() {
            EventBus.$emit('formular:show');
        },
    }
}
</script>

<style lang="scss">
</style>
