<template>
    <h2 class="formular-chapter" v-html="title"></h2>
</template>

<script>
export default {
    name: "FormularChapter",
    props: {
        title: null
    }
}
</script>

<style lang="scss" scoped>
.formular-chapter{
    display:inline-block;
    width:auto;
    padding: 3px 40px;
    background: #E5236A;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom:5px;
    font-size: 18px;

    background: linear-gradient(120deg,
            transparent 0%,
            transparent 20px,
            #E5236A 20px,
            #E5236A calc(100% - 20px),
            transparent calc(100% - 20px),
            transparent 100%
    );
}
</style>
