<i18n>
{
    "en": {
        "day": "day",
        "days": "days",
        "hour": "hour",
        "hours": "hours",
        "minute": "minute",
        "minutes": "minutes",
        "second": "second",
        "seconds": "second"
    },
    "fr": {
        "day": "jour",
        "days": "jours",
        "hour": "heure",
        "hours": "heures",
        "minute": "minute",
        "minutes": "minutes",
        "second": "seconde",
        "seconds": "secondes"
    }
}
</i18n>
<template>
    <ul class="vuejs-countdown">
        <li v-if="days > 0 || true">
            <p class="digit">
                <span>
                    {{ days | twoDigits | firstDigit }}
                </span>
                <span>
                    {{ days | twoDigits | secondDigit }}
                </span>
            </p>
            <p class="text">
                {{ days !== 1 ? $t('days') : $t('day') }}
            </p>
        </li>
        <li>
            <p class="digit">
                <span>
                    {{ hours | twoDigits | firstDigit }}
                </span>
                <span>
                    {{ hours | twoDigits | secondDigit }}
                </span>
            </p>
            <p class="text">
                {{ hours !== 1 ? $t('hours') : $t('hour') }}
            </p>
        </li>
        <li>
            <p class="digit">
                <span>
                    {{ minutes | twoDigits | firstDigit }}
                </span>
                <span>
                    {{ minutes | twoDigits | secondDigit }}
                </span>
            </p>
            <p class="text">
                {{ minutes !== 1 ? $t('minutes') : $t('minute') }}
            </p>
        </li>
        <li>
            <p class="digit">
                <span>
                    {{ seconds | twoDigits | firstDigit }}
                </span>
                <span>
                    {{ seconds | twoDigits | secondDigit }}
                </span>
            </p>
            <p class="text">
                {{ seconds !== 1 ? $t('seconds') : $t('second') }}
            </p>
        </li>
    </ul>
</template>

<script>
let interval = null;
export default {
    name: 'CountDown',
    props: {
        deadline: {
            type: String
        },
        end: {
            type: String
        },
        stop: {
            type: Boolean
        }
    },
    data() {
        return {
            now: Math.trunc((new Date()).getTime() / 1000),
            date: null,
            diff: 0
        }
    },
    mounted() {
        if (!this.deadline && !this.end) {
            throw new Error("Missing props 'deadline' or 'end'");
        }
        let endTime = this.deadline ? this.deadline : this.end;
        this.date = Math.trunc(Date.parse(endTime.replace(/-/g, "/")) / 1000);
        if (!this.date) {
            throw new Error("Invalid props value, correct the 'deadline' or 'end'");
        }
        interval = setInterval(() => {
            this.now = Math.trunc((new Date()).getTime() / 1000);
        }, 1000);
    },
    computed: {
        seconds() {
            return Math.trunc(this.diff) % 60
        },
        minutes() {
            return Math.trunc(this.diff / 60) % 60
        },
        hours() {
            return Math.trunc(this.diff / 60 / 60) % 24
        },
        days() {
            return Math.trunc(this.diff / 60 / 60 / 24)
        }
    },
    watch: {
        now(value) {
            this.diff = this.date - this.now;
            if (this.diff <= 0 || this.stop) {
                this.diff = 0;
                // Remove interval
                clearInterval(interval);
            }
        }
    },
    filters: {
        twoDigits(value) {
            if (value.toString().length <= 1) {
                return '0' + value.toString()
            }
            return value.toString()
        },
        firstDigit(value) {
            return (value).toString()[0]
        },
        secondDigit(value) {
            return (value).toString()[1]
        }
    },
    destroyed() {
        clearInterval(interval);
    }
}
</script>
<style lang="scss" scoped>
.vuejs-countdown {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

.vuejs-countdown li {
    display: inline-block;
    margin: 0 1px;
    text-align: center;
    position: relative;

    @media(min-width: 1024px) {
        margin: 0 5px;
    }
}

.vuejs-countdown li p {
    margin: 0;
}

.vuejs-countdown li:after {
    content: "";
    position: absolute;
    top: 0;
    right: -13px;
    font-size: 32px;
}

.vuejs-countdown li:first-of-type {
    margin-left: 0;
}

.vuejs-countdown li:last-of-type {
    margin-right: 0;
}

.vuejs-countdown li:last-of-type:after {
    content: "";
}

.vuejs-countdown .digit {
    font-size: 48px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 0;

    display: flex;
    flex-wrap: nowrap;

    span {
        background-image: linear-gradient(to bottom,
                #45cb9f 0%,
                #45cb9f 50%,
                #32ae81 50%,
                #45cb9f 100%
        );
        border: 3px solid #fff;
        color: #fff;

        width: 30px;
        height: 45px;
        margin: 0 3px;
        border-radius: 8px;

        font-size: 36px;
        line-height: 1.1;


        @media(min-width: 768px) {
            font-size: 27px;
            width: 26px;
            height: 40px;
            line-height: 1.4;
            margin: 0 1px;
        }

        @media(min-width: 1024px) {
            font-size: 36px;
            width: 30px;
            height: 45px;
            line-height: 1.2;
            margin: 0 3px;
        }

        @media(min-width: 1200px) {
            font-size: 48px;
            width: 40px;
            height: 60px;
            line-height: 1.2;
            margin: 0 3px;
        }

    }
}

.vuejs-countdown .text {
    text-transform: uppercase;
    margin: 10px auto 0;
    font-size: 10px;
    color: #fff;

    @media(min-width: 992px) {
        font-size: 14px;
    }

}
</style>
