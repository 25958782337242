<template>
    <div class="bg-pink-200 flex flex-row flex-nowrap justify-start align-top p-1 m-1 _w-1/2">
        <label v-if="label"
                class="flex-auto whitespace-nowrap"
                v-html="label"
        ></label>
        <input
                type="text"
                @input="handleInput"
                class="border-b border-gray-400 focus:outline-none px-1 py-0 flex-auto bg-transparent text-gray-500"
                :disabled="options && options.disabled"
                :value="value"
        />
    </div>
</template>

<script>
export default {
    name: 'FormularInput',

    props: {
        value: null,
        label: null,
        options: null,
    },

    data() {
        return {
            content: this.value
        }
    },

    methods: {
        handleInput(el) {
            this.$emit('input', el.target.value)
        },

        resizeFields(){

            let vm = this;

            let sto = setTimeout(function(){
                clearTimeout(sto);

                let container  = $(vm.$el);
                let label = $(container).find('label');
                let input = $(container).find('input');

                $(input).css({
                    width: ($(container).outerWidth() - ( $(label).outerWidth() || 0 ) - 15)
                            / $(container).outerWidth()
                            * 100
                            + "%"
                });

            },500)
        },

        emitResizeFields(){
            let vm = this;
            let sto = setTimeout(function(){
                clearTimeout(sto);
                window.dispatchEvent( new Event('resize'));
            }, 1000)
        }
    },
    mounted(){
        let vm = this;
        window.addEventListener('resize', vm.resizeFields);
        vm.resizeFields();
        EventBus.$on('formular:resize-fields', vm.emitResizeFields) ;
    },
    beforeDestroy() {
        let vm = this;
        window.removeEventListener('resize', vm.resizeFields);
        EventBus.$off('formular:resize-fields', vm.emitResizeFields) ;
    }
}
</script>

<style scoped>
label{
    font-size: 14px;
}
input{
    font-size: 14px;
}

</style>
