<i18n>
{
    "en": {
        "txt_alert_success_title": "Success!",
        "txt_alert_error_title": "Error!",
        "txt_alert_error_message": "Sorry, your form has not been sent. Please try again later.",

        "title_company_name": "COMPANY NAME",
        "company_name": {
            "title": "en",
            "vta": "VAT number*"
        },
        "title_customer": "CUSTOMER",
        "customer": {
            "gender_ms": "Ms.*",
            "gender_mr": "Mr.*",
            "name": "Name*",
            "first_name": "First name*",
            "email": "Email*",
            "phone": "Phone number*"
        },
        "title_order": "REQUIREMENT",
        "order": {
            "quantity_requested": "Quantity*"
        },
        "title_delivery_detail": "DELIVERY DETAIL",
        "delivery": {
            "gender_ms": "Ms.*",
            "gender_mr": "Mr.*",
            "name": "Name*",
            "first_name": "First name*",
            "email": "Email*",
            "phone": "Phone number*",
            "address": "Address:*",
            "addresses_aditional": "",
            "post_code": "Postcode*",
            "city": "City*",
            "country": "Country area*"
        },
        "txtMandatory": "Fields marked with an asterisk * are mandatory",
        "btnSubmit": "SEND MY REQUEST"
    },
    "fr": {
        "txt_alert_success_title": "Success !",
        "txt_alert_error_title": "Erreur !",
        "txt_alert_error_message": "Désolé, votre formulaire n'a pas été envoyé. Veuillez réessayer plus tard.",

        "title_company_name": "NOM DE L’ENTREPRISE",
        "company_name": {
            "title": "",
            "vta": "Numéro de TVA*"
        },
        "title_customer": "CLIENT",
        "customer": {
            "gender_ms": "Mme.*",
            "gender_mr": "M.*",
            "name": "Nom*",
            "first_name": "Prénom*",
            "email": "Courriel*",
            "phone": "Numéro de téléphone*"
        },
        "title_order": "BESOIN",
        "order": {
            "quantity_requested": "Quantité*"
        },
        "title_delivery_detail": "DÉTAIL DE LA LIVRAISON",
        "delivery": {
            "gender_ms": "Mme.*",
            "gender_mr": "M.*",
            "name": "Nom*",
            "first_name": "Prénom*",
            "email": "Courriel*",
            "phone": "Numéro de téléphone*",
            "address": "Adresse*",
            "addresses_aditional": "",
            "post_code": "Code postal*",
            "city": "Ville*",
            "country": "Zone du pays*"
        },
        "txtMandatory": "Les champs marqués d’un astérisque * sont obligatoires.",
        "btnSubmit": "ENVOYER MA DEMANDE"
    }
}
</i18n>

<template>
    <div id="formular" class="_invisible">
        <div class="formular-sheet">
            <!--  -->
            <div class="formular-close" @click="hideFormular()">
                <span>x</span>
            </div>

            <!--  -->
            <div class="formular-content ">
                <div class="flex justify-center align-top flex-col-reverse lg:flex-row px-5 py-5">

                    <!--  -->
                    <div class="w-full lg:w-1/2 px-0 lg:px-5 py-0 _bg-yellow-200">

                        <!-- company name -->
                        <formular-chapter :title=" $t('title_company_name')"/>
                        <div class="flex justify-between flex-col lg:flex-row align-top mb-5">
                            <formular-input v-model="company_name.title" label="" class="w-full lg:w-6/12" :options="{ disabled: false }"/>
                            <formular-input v-model="company_name.vta" :label="$t('company_name.vta')" class="w-full lg:w-6/12"/>
                        </div>

                        <!-- customer -->
                        <formular-chapter :title=" $t('title_customer')"/>
                        <div class="flex justify-start flex-row align-top  mb-2">
                            <formular-radio v-model="customer.gender" :label="$t('customer.gender_ms')" class="w-20" :options="{ name: 'customer_gender', value:'ms'}"/>
                            <formular-radio v-model="customer.gender" :label="$t('customer.gender_mr')" class="w-20" :options="{ name: 'customer_gender', value:'mr'}"/>
                        </div>
                        <div class="flex justify-between  flex-col lg:flex-row align-top  mb-2">
                            <formular-input v-model="customer.name" :label="$t('customer.name')" class="w-full lg:w-5/12"/>
                            <formular-input v-model="customer.first_name" :label="$t('customer.first_name')" class="w-full lg:w-7/12"/>
                        </div>
                        <div class="flex justify-between  flex-col lg:flex-row align-top  mb-5">
                            <formular-input v-model="customer.email" :label="$t('customer.email')" class="w-full lg:w-6/12"/>
                            <formular-input v-model="customer.phone" :label="$t('customer.phone')" class="w-full lg:w-6/12"/>
                        </div>

                        <!-- order -->
                        <formular-chapter :title=" $t('title_order')"/>
                        <div class="flex justify-between  flex-col lg:flex-row  align-top mb-5">
                            <formular-input v-model="order.quantity_requested" :label="$t('order.quantity_requested')" class="w-full lg:w-full"/>
                        </div>

                        <!-- delivery detail -->
                        <formular-chapter :title=" $t('title_delivery_detail')"/>
                        <div class="flex justify-start flex-row align-top  mb-2">
                            <formular-radio v-model="delivery.gender"  :label="$t('delivery.gender_ms')"  class="w-20" :options="{ name: 'delivery_gender', value:'ms'}"/>
                            <formular-radio v-model="delivery.gender" :label="$t('delivery.gender_mr')" class="w-20" :options="{ name: 'delivery_gender', value:'mr'}"/>
                        </div>
                        <div class="flex justify-between  flex-col lg:flex-row align-top mb-2">
                            <formular-input v-model="delivery.name" :label="$t('delivery.name')" class="w-full lg:w-5/12"/>
                            <formular-input v-model="delivery.first_name" :label="$t('delivery.first_name')" class="w-full lg:w-7/12"/>
                        </div>
                        <div class="flex justify-between  flex-col lg:flex-row align-top  mb-2">
                            <formular-input v-model="delivery.email" :label="$t('delivery.email')" class="w-full lg:w-6/12"/>
                            <formular-input v-model="delivery.phone" :label="$t('delivery.phone')" class="w-full lg:w-6/12"/>
                        </div>
                        <div class="flex justify-between flex-col lg:flex-row  align-top  mb-2">
<!--                            <formular-input v-model="delivery.street_no" label="Street No.*" class="w-full lg:w-4/12"/>-->
<!--                            <formular-input v-model="delivery.address" label="Address*" class="w-full lg:w-8/12"/>-->
                            <formular-input v-model="delivery.address" :label="$t('delivery.address')" class="w-full lg:w-full"/>
                        </div>
                        <div class="flex justify-between  flex-col lg:flex-row align-top  mb-2">
                            <formular-input v-model="delivery.addresses_aditional" label="" class="w-full lg:w-full"/>
                        </div>
                        <div class="flex justify-between  flex-col lg:flex-row align-top  mb-2">
                            <formular-input v-model="delivery.post_code" :label="$t('delivery.post_code')" class="w-full lg:w-4/12"/>
                            <formular-input v-model="delivery.city" :label="$t('delivery.city')" class="w-full lg:w-8/12"/>
                        </div>
                        <div class="flex justify-between  flex-col lg:flex-row align-top">
                            <formular-input v-model="delivery.country" :label="$t('delivery.country')" class="w-full lg:w-full"/>
                        </div>

                        <div class="w-full my-5 flex lg:hidden  flex-col">
                            <p class="fa-bold text-black mx-auto mb-3" v-html="$t('txtMandatory')" ></p>
                            <button class="formular-button mx-auto w-full md:w-4/6" @click="submit()">{{ $t('btnSubmit') }}</button>
                        </div>

                    </div>

                    <!--  -->
                    <div class="w-full lg:w-1/2 px-5 py-5 flex justify-around items-center flex-col">
                        <div class="w-full mb-10 lg:mb-5">
<!--                            <img src="../assets/img/formular_title.jpg" class="w-4/6 lg:w-1/2 mx-auto"/>-->
                            <img :src=" $t('images.' + $config.project + '.formular_title') " class="w-4/6 lg:w-1/2 mx-auto"/>
                        </div>
                        <div class="w-full mb-10 lg:mb-5">
<!--                            <img src="../assets/img/formular_dooor.jpg" class="w-2/3 lg:w-1/3 mx-auto"/>-->
                            <img :src=" $t('images.' + $config.project + '.formular_dooor') "class="w-2/3 lg:w-1/3 mx-auto"/>
                        </div>
                        <div class="w-full mb-10 lg:mb-5 hidden lg:flex flex-col">
                            <p class="fa-bold text-black mx-auto mb-3 text-xs" v-html="$t('txtMandatory')" ></p>
                            <button class="formular-button mx-auto w-full xl:w-4/6" @click="submit()">{{ $t('btnSubmit') }}</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

import FormularInput from "./FormularInput";
import FormularChapter from "./FormularChapter";
import FormularRadio from "./FormularRadio";

export default {
    name: "Formular",
    components: {FormularRadio, FormularChapter, FormularInput },

    data() {
        return {
            company_name: {
                title: '',
                vta: '',
            },
            customer: {
                gender: '',
                name: '',
                first_name: '',
                email: '',
                phone: '',
            },
            order: {
                quantity_requested: ''
            },
            delivery: {
                gender: '',
                name: '',
                first_name: '',
                email: '',
                phone: '',
                address: '',
                addresses_aditional: '',

                post_code: '',
                city: '',
                country: '',

            },
        }
    },

    methods: {
        showFormular() {
            $('#formular').animate({
                opacity: 1,
                maxWidth: 3000
            }, 700);
            EventBus.$emit('formular:resize-fields');
        },
        hideFormular() {
            $('#formular').animate({
                opacity: 0,
                maxWidth: 0
            }, 700);
            this.emptyFields();
        },

        emptyFields() {
            this.company_name = {
                title: '',
                vta: '',
            };
            this.customer = {
                gender: '',
                name: '',
                first_name: '',
                email: '',
                phone: '',
            };
            this.order = {
                quantity_requested: ''
            };
            this.delivery = {
                gender: '',
                name: '',
                first_name: '',
                email: '',
                phone: '',

                address: '',
                addresses_aditional: '',

                post_code: '',
                city: '',
                country: '',

            };
        },

        submit() {
            let vm = this;
            //
            let post_data = new FormData();
            post_data.append('company_name_title', vm.company_name.title);
            post_data.append('company_name_vta', vm.company_name.vta);

            post_data.append('customer_gender', vm.customer.gender);
            post_data.append('customer_name', vm.customer.name);
            post_data.append('customer_first_name', vm.customer.first_name);
            post_data.append('customer_email', vm.customer.email);
            post_data.append('customer_phone', vm.customer.phone);

            post_data.append('order_quantity_requested', vm.order.quantity_requested);

            post_data.append('delivery_gender', vm.delivery.gender);
            post_data.append('delivery_name', vm.delivery.name);
            post_data.append('delivery_first_name', vm.delivery.first_name);
            post_data.append('delivery_email', vm.delivery.email);
            post_data.append('delivery_phone', vm.delivery.phone);
            post_data.append('delivery_address', vm.delivery.address);
            post_data.append('delivery_addresses_aditional', vm.delivery.addresses_aditional);
            post_data.append('delivery_post_code', vm.delivery.post_code);
            post_data.append('delivery_city', vm.delivery.city);
            post_data.append('delivery_country', vm.delivery.country);
            //
            post_data.append('language', vm.$i18n.locale);
            post_data.append('project', vm.$config.project);
            //

            let service = axios.create({
                baseURL: process.env.NODE_ENV === "development"
                        ? 'http://localhost/altavia/wall/public/api/'
                        : vm.$config.api.path,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });

            service
                    .post('/', post_data)
                    .then(function (response) {
                        vm.hideFormular();
                        $.alert({
                            columnClass: 'w-full sm:w-96 mx-auto text-red-300',
                            theme: 'material',
                            title: vm.$t('txt_alert_success_title'),
                            content: response.data,
                        });
                    })
                    .catch(function (error) {
                        if(error.response){
                            let response = error.response.data;
                            $.alert({
                                columnClass: 'w-full sm:w-96 mx-auto text-red-300',
                                theme: 'material',
                                title: vm.$t('txt_alert_error_title'),
                                content: response.error_msg
                            });
                        }else{
                            $.alert({
                                columnClass: 'w-full sm:w-96 mx-auto text-red-300',
                                theme: 'material',
                                title: vm.$t('txt_alert_error_title'),
                                content: response.error_msg
                            });
                        }

                    });

        },

    },

    mounted() {
        EventBus.$on('formular:show', this.showFormular)
        EventBus.$on('formular:hide', this.hideFormular)
    },
    beforeDestroy() {
        EventBus.$off('formular:show', this.showFormular)
        EventBus.$off('formular:hide', this.hideFormular)
    }
}
</script>

<style scoped lang="scss">
#formular {
    @apply fixed block top-0 left-0 w-full h-full;
    max-width: 0;
    opacity: 0;
    overflow: hidden;
    background: rgba(#000, 0.7);
    z-index: 100;

    .formular-close {
        position: relative;
        display: flex;
        width: 60px;
        height: 50px;
        justify-content: center;
        align-items: center;
        background: #fff;
        color: #E5236A;
        top: 5px;
        right: 00px;
        cursor: pointer;
        z-index: 1;
        float: right;

        span {
            font-size: 32px;
            line-height: 1;
            height: 40px;
        }

        @media(min-width: 768px) {
            position: absolute;
            top: 5px;
            right: -60px;
            border: 1px solid rgba(#000, 0.5);
        }

        @media(min-width: 992px) {

            top: 5px;
            right: -60px;
        }
    }

    .formular-sheet {
        background: #fff;
        position: relative;
        display: block;
        max-width: 1330px;
        top: 0;
        left: 0;
        border: 1px solid rgba(#000, 0.5);

        width: 100%;
        height: 100%;
        max-height: calc(100% - 0px);

        @media(min-width: 768px) {
            width: calc(100% - 20px - 65px);
            max-height: calc(100% - 80px);
            top: 40px;
            left: 10px;
        }

        @media(min-width: 1024px) {
            height: calc( 100% - 80px);
            width: calc(100% - 40px - 65px - 10px);
            max-height: 775px;
        }

    }

    .formular-content {
        overflow: auto;
        width: 100%;
        height: calc(100% - 50px);
        position: relative;
        display: block;

        @media(min-width: 768px) {
            height: calc(100% - 0px);

            overflow:hidden;
            overflow-y:auto;
        }


    }


    .formular-button {
        display: inline-block;
        //width:auto;
        padding: 3px 40px;
        background: #E5236A;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 18px;

        background: linear-gradient(120deg,
                transparent 0%,
                transparent 20px,
                #E5236A 20px,
                #E5236A calc(100% - 20px),
                transparent calc(100% - 20px),
                transparent 100%
        );
    }
}
</style>

