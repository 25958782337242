module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
        "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
        "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hour"])},
        "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours"])},
        "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minute"])},
        "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
        "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["second"])},
        "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["second"])}
      },
      "fr": {
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jour"])},
        "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours"])},
        "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heure"])},
        "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heures"])},
        "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minute"])},
        "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
        "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconde"])},
        "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secondes"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"day":"day","days":"days","hour":"hour","hours":"hours","minute":"minute","minutes":"minutes","second":"second","seconds":"second"},"fr":{"day":"jour","days":"jours","hour":"heure","hours":"heures","minute":"minute","minutes":"minutes","second":"seconde","seconds":"secondes"}}')
  delete Component.options._Ctor
  
}
