import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const  router = new VueRouter({
    routes: [
        {
            path: '*',
            redirect: '/',
        },
        {
            path: '/:language?',
            component: require('./../views/Home').default,
            props: true,
        },
    ]
});
export default router;
