<template>
    <div class="flex relative justify-center items-center mt-10">
            <img  :src="options.image">      
    </div>
</template>

<script>
export default {
    name: "ImageDescription",
    props: {
      options: null
    }
  };
</script>

<style lang="scss" scoped>

</style>
