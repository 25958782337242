import './assets/css/tailwind.css'
import './assets/css/main.scss'

// axios
import axios from 'axios';
window.axios = axios;

// jquery + jquery-confirm
import $ from 'jquery';
require('jquery-confirm');
window.$ = window.jQuery = $;

//
import Vue from 'vue'
import App from './App.vue'
import i18n from './locales'
import router from './router'

Vue.config.productionTip = false

// config
Vue.prototype.$config = window.Config = {
    ...{
        language: 'en',
        project: 'wall',
        api: {
            path: "./api/",
        },
    },
    ...(window.Config || {}) ,
}

// eventbus
const EventBus = new Vue({});
window.EventBus = EventBus;

// application
const Application = new Vue({
    i18n, router,
    render: function (h) {
        return h(App)
    }
});
Application.$mount('#app');
window.Application = Application;
