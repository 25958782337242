module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "txt_alert_success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
        "txt_alert_error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])},
        "txt_alert_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, your form has not been sent. Please try again later."])},
        "title_company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPANY NAME"])},
        "company_name": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
          "vta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT number*"])}
        },
        "title_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUSTOMER"])},
        "customer": {
          "gender_ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ms.*"])},
          "gender_mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr.*"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name*"])},
          "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name*"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email*"])},
          "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number*"])}
        },
        "title_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REQUIREMENT"])},
        "order": {
          "quantity_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity*"])}
        },
        "title_delivery_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DELIVERY DETAIL"])},
        "delivery": {
          "gender_ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ms.*"])},
          "gender_mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr.*"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name*"])},
          "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name*"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email*"])},
          "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number*"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address:*"])},
          "addresses_aditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "post_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode*"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City*"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country area*"])}
        },
        "txtMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields marked with an asterisk * are mandatory"])},
        "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEND MY REQUEST"])}
      },
      "fr": {
        "txt_alert_success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success !"])},
        "txt_alert_error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur !"])},
        "txt_alert_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, votre formulaire n'a pas été envoyé. Veuillez réessayer plus tard."])},
        "title_company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOM DE L’ENTREPRISE"])},
        "company_name": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "vta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de TVA*"])}
        },
        "title_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLIENT"])},
        "customer": {
          "gender_ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mme.*"])},
          "gender_mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M.*"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom*"])},
          "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom*"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel*"])},
          "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone*"])}
        },
        "title_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BESOIN"])},
        "order": {
          "quantity_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité*"])}
        },
        "title_delivery_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DÉTAIL DE LA LIVRAISON"])},
        "delivery": {
          "gender_ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mme.*"])},
          "gender_mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M.*"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom*"])},
          "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom*"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel*"])},
          "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone*"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse*"])},
          "addresses_aditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
          "post_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal*"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville*"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone du pays*"])}
        },
        "txtMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les champs marqués d’un astérisque * sont obligatoires."])},
        "btnSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENVOYER MA DEMANDE"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"txt_alert_success_title":"Success!","txt_alert_error_title":"Error!","txt_alert_error_message":"Sorry, your form has not been sent. Please try again later.","title_company_name":"COMPANY NAME","company_name":{"title":"en","vta":"VAT number*"},"title_customer":"CUSTOMER","customer":{"gender_ms":"Ms.*","gender_mr":"Mr.*","name":"Name*","first_name":"First name*","email":"Email*","phone":"Phone number*"},"title_order":"REQUIREMENT","order":{"quantity_requested":"Quantity*"},"title_delivery_detail":"DELIVERY DETAIL","delivery":{"gender_ms":"Ms.*","gender_mr":"Mr.*","name":"Name*","first_name":"First name*","email":"Email*","phone":"Phone number*","address":"Address:*","addresses_aditional":"","post_code":"Postcode*","city":"City*","country":"Country area*"},"txtMandatory":"Fields marked with an asterisk * are mandatory","btnSubmit":"SEND MY REQUEST"},"fr":{"txt_alert_success_title":"Success !","txt_alert_error_title":"Erreur !","txt_alert_error_message":"Désolé, votre formulaire n\u0027a pas été envoyé. Veuillez réessayer plus tard.","title_company_name":"NOM DE L’ENTREPRISE","company_name":{"title":"","vta":"Numéro de TVA*"},"title_customer":"CLIENT","customer":{"gender_ms":"Mme.*","gender_mr":"M.*","name":"Nom*","first_name":"Prénom*","email":"Courriel*","phone":"Numéro de téléphone*"},"title_order":"BESOIN","order":{"quantity_requested":"Quantité*"},"title_delivery_detail":"DÉTAIL DE LA LIVRAISON","delivery":{"gender_ms":"Mme.*","gender_mr":"M.*","name":"Nom*","first_name":"Prénom*","email":"Courriel*","phone":"Numéro de téléphone*","address":"Adresse*","addresses_aditional":"","post_code":"Code postal*","city":"Ville*","country":"Zone du pays*"},"txtMandatory":"Les champs marqués d’un astérisque * sont obligatoires.","btnSubmit":"ENVOYER MA DEMANDE"}}')
  delete Component.options._Ctor
  
}
