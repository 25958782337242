<template>
    <div class="container-fluid">
        <header class="fixed block top-0 left-0 w-full overflow-hidden">
            <nav class="flex flex-wrap w-full justify-center items-center py-1 px-1 md: px-3 mt-10">
                <!---->
                <div class="flex flex-auto justify-center items-start flex-col w-6/12 md:w-4/12">
                    <div class="flex justify-center items-center flex-row">
                        <img :src=" $t('images.' + $config.project + '.icon_title') ">
                        <h3 class="text-color-logo text-red-500 font-semibold sm:text-sm text-md md:text-xl xl:text-3xl" v-html=" $t('txt_001')"></h3>
                    </div>
                    <p class="text-center font-semibold text-white text-md md:text-xl" v-html=" $t('txt_002')"></p>
                </div>
                <!---->
                <div class="flex flex-auto flex-col justify-center items-end md:items-center w-6/12 md:w-4/12 text-right md:text-center">
                    <p class=" text-white font-semibold uppercase sm:text-sm text-md md:text-xl xl:text-3xl" v-html=" $t('txt_003')"></p>
                    <p class="text-white font-semibold uppercase sm:text-sm text-md md:text-xl xl:text-3xl" v-html=" $t('txt_004')"></p>
                </div>
                <!---->
                <div class="flex flex-auto justify-center md:justify-end items-center md:w-4/12 w-full mt-5 md:mt-0">
                    <count-down :deadline="$t('countdown')" :key="$route.fullPath"/>
                </div>
            </nav>

            <!--            -->
            <div class="absolute top-1 right-5" style="font-family: 'Trebuchet MS' , sans-serif;">
                <button @click="changeLanguage('fr')" class="text-white font-normal text-lg  sm:text-sm md:text-xl" :class="[ $i18n.locale === 'fr' ? 'router-link-active' : '']">FR</button>
                <span class="text-white font-normal sm:text-sm text-lg md:text-xl">|</span>
                <button @click="changeLanguage('en')" class="text-white font-normal sm:text-sm text-lg md:text-xl" :class="[ $i18n.locale  === 'en' ? 'router-link-active' : '']">EN</button>
            </div>
        </header>

        <main class="relative block w-full overflow-hidden mt-94">

            <!--            -->
            <div class="section1 relative md:hidden w-full">
                <img :src=" $t('images.' + $config.project + '.description_left')" class="w-full h-full">
                <img :src=" $t('images.' + $config.project + '.description_right')" class="w-full h-full">
            </div>
            <div class="section1 relative hidden md:block w-full">
                <img :src=" $t('images.' + $config.project + '.description_background')" class="w-full h-full">
            </div>

            <!--            -->
            <div class=" flex justify-center items-center mt-10 ">
                <h3 v-html="$t('title_table')" class="text-center uppercase text-2xl md:text-4xl text-gray-700"></h3>
            </div>
            <div class="container relative block mx-auto px-2">
                <div class=" flex relative justify-center items-center mt-10">
                    <img :src=" $t('images.' + $config.project + '.table_desktop')" class="hidden md:block">
                    <img :src=" $t('images.' + $config.project + '.table_mobile')" class="md:hidden">
                </div>
            </div>


            <!--            -->
            <div class="container relative block mx-auto px-2">
                <div class=" flex justify-center items-center mt-10 ">
                    <h3 v-html="$t('title_order')" class="text-center uppercase text-2xl md:text-4xl  text-gray-700"></h3>
                </div>
                <div class="flex relative justify-center items-center mt-10 ">
                    <img :src=" $t('images.' + $config.project + '.image_001')">
                </div>
                <div class="flex relative justify-center items-center mt-10 ">
                    <img :src=" $t('images.' + $config.project + '.image_002')">
                </div>
                <div class="flex justify-center items-center mt-10 ">
                    <img :src=" $t('images.' + $config.project + '.image_003')">
                </div>
                <div class="flex justify-center items-center mt-10 ">
                    <img :src=" $t('images.' + $config.project + '.image_004')">
                </div>
            </div>

            <!--            -->
            <div class="container relative block mx-auto px-2">

                <div class="order-image flex justify-center items-center text-center my-10">
                    <p class="formular-chapter sm:text-lg text-xl md:text-5xl" @click="openFormular()" v-html=" $t('txt_005')" :class="[ $i18n.locale === 'en' ? 'formular-chapter-en' : 'formular-chapter-fr']"></p>
                </div>

            </div>
        </main>

    </div>
</template>

<script>
import ImageDescription from "./../components/ImageDescription";
import CountDown from "./../components/CountDown";

export default {
    name: "Home",
    components: {CountDown, ImageDescription},
    props: {
        language: null,
    },

    methods: {
        openFormular() {
            EventBus.$emit('formular:show');
        },
        changeLanguage(language) {
            let vm = this;
            this.$i18n.locale = language;
            vm.$router.push('/' + language).catch(function () {
            });
        },
    },
    mounted() {
        let vm = this;

        let language = vm.language || vm.$config.language;

        vm.$i18n.locale = language;

        if (!vm.language) {
            vm.$router.push('/' + language).catch(function () {
            });
        }

        //
        window.addEventListener('resize', function () {
            $('main').css({
                top: $('header').outerHeight()
            })
        })
        $('main').css({
            top: $('header').outerHeight()
        })
    }
}
</script>


<style scoped lang="scss">

header {
    z-index: 1;
    background-color: #454f50;
    border: solid 1px white;
}

nav {
    height: auto;


}

.p-color-text {
    color: #444e4d
}

.text-color-logo {
    color: #e5236a;
}


.formular-chapter-en {
    position: relative;
    display: inline-block;
    width: auto;
    padding: 25px 100px;
    background: #E5236A;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 18px;
    clip-path: polygon(10% 0, 100% 0, 90% 100%, 0% 100%);
    box-sizing: border-box;
    font-size: 2.2rem;

    &::before {
        content: "";
        position: absolute;
        top: -11px;
        left: -1.4px;
        padding: 107px 0px 19px 11px;
        margin-left: 10px;
        background-color: #E5236A;
        box-sizing: border-box;
        -webkit-transform: rotate(36deg);
        transform: rotate(29.9deg);
    }

    &::after {
        content: "";
        position: absolute;
        top: -7.7px;
        right: 21.5px;
        padding: 107px 0px 19px 11px;
        margin-left: 10px;
        background-color: #E5236A;
        box-sizing: border-box;
        -webkit-transform: rotate(36deg);
        transform: rotate(29.9deg);
    }

    &:hover {
        color: #E5236A;
        background-color: #fff;
        cursor: pointer;
        border: 2px solid #E5236A;
        box-sizing: border-box;
    }

    @media (max-width: 768px) {
        padding: 7px 40px;
        font-size: 1.9rem;

        &::before {
            content: "";
            position: absolute;
            top: -11.8px;
            left: -10.3px;
            padding: 60px 0px 19px 11px;
            margin-left: 10px;
            background-color: #E5236A;
            box-sizing: border-box;
            -webkit-transform: rotate(29deg);
            transform: rotate(38deg);
        }
        &::after {
            content: "";
            position: absolute;
            top: -5.4px;
            right: 13.5px;
            padding: 55px 0px 19px 11px;
            margin-left: 10px;
            background-color: #E5236A;
            box-sizing: border-box;
            -webkit-transform: rotate(36deg);
            transform: rotate(36.5deg);
        }
    }
}

.formular-chapter-fr {
    position: relative;
    display: inline-block;
    width: auto;
    padding: 25px 100px;
    background: #E5236A;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 18px;
    clip-path: polygon(10% 0, 100% 0, 90% 100%, 0% 100%);
    box-sizing: border-box;
    font-size: 2.2rem;

    &::before {
        content: "";
        position: absolute;
        top: -16px;
        left: 7.5px;
        padding: 107px 0px 19px 11px;
        margin-left: 10px;
        background-color: #E5236A;
        box-sizing: border-box;
        -webkit-transform: rotate(36deg);
        transform: rotate(36.9deg);
    }

    &::after {
        content: "";
        position: absolute;
        top: -11.7px;
        right: 27.5px;
        padding: 107px 0px 19px 11px;
        margin-left: 10px;
        background-color: #E5236A;
        box-sizing: border-box;
        -webkit-transform: rotate(36deg);
        transform: rotate(36.9deg);
    }

    &:hover {
        color: #E5236A;
        background-color: #fff;
        cursor: pointer;
        border: 2px solid #E5236A;
        box-sizing: border-box;
    }

    @media (max-width: 768px) {
        padding: 7px 40px;
        font-size: 1.9rem;

        &::before {
            content: "";
            position: absolute;
            top: -15.8px;
            left: -5.3px;
            padding: 70px 0px 19px 11px;
            margin-left: 10px;
            background-color: #E5236A;
            box-sizing: border-box;
            -webkit-transform: rotate(29deg);
            transform: rotate(45deg);
        }
        &::after {
            content: "";
            position: absolute;
            top: -13.4px;
            right: 22.5px;
            padding: 70px 0px 19px 11px;
            margin-left: 10px;
            background-color: #E5236A;
            box-sizing: border-box;
            -webkit-transform: rotate(36deg);
            transform: rotate(45.5deg);
        }
    }

}

.router-link-active {
    color: #E5236A;
}

</style>
