<template>
    <div class="inline-flex  flex-row flex-nowrap justify-start align-top p-1 m-1 _w-1/2">

        <label v-if="label"
               :for="'' + ID"
               class="whitespace-nowrap mr-3"
               v-html="label"
        ></label>

        <label
                :for=" '' + ID "
                class="w-5 h-5 border border-custom-pink cursor-pointer inline-block"
        >

            <input
                    type="radio"
                    :name="options && options.name ? options.name : '' "
                    @change="handleInput"
                    class="hidden"
                    :value="options.value"
                    :id="ID"
            />
            <span class="hidden"/>
        </label>


    </div>
</template>

<script>
export default {
    name: 'FormularRadio',

    props: {
        value: null,
        label: null,
        options: null,
    },

    data() {
        return {
            ID: 'radio_' +  Math.ceil(Math.random() * 9999999 + 1000000),
            checked: this.value
        }
    },

    methods: {
        handleInput(el) {
            let vm = this;
            vm.checked = el.target.value || '';
            vm.$emit('input', vm.checked);
        },
    },

}
</script>

<style scoped>
label {
    font-size: 14px;
}

.border-custom-pink {
    border-color:#E5236A
}

span {
    display: none;
    background: #E5236A;
    margin: 2px;
    width: calc( 100% - 4px);
    height: calc( 100% - 4px);
}

input:checked ~ span {
    display: block;
}

</style>
